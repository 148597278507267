<template>
  <v-card
    class="pa-4 mt-5 card-content d-flex flex-column justify-space-between"
  >
    <div>
      <v-row class="ma-0">
        <v-col cols="12" md="3">
          <v-text-field
            dense
            v-model.lazy="filters.produtor"
            append-icon="mdi-magnify"
            outlined
            color="primary"
            :label="$t('labels.productor_or_group')"
            type="text"
          />
        </v-col>

        <v-col cols="12" md="2">
          <v-select
            v-model.lazy="filters.tipo"
            dense
            :items="producerTypes"
            item-text="name"
            item-value="id"
            outlined
            :color="'primary'"
            :label="$t('labels.type')"
          />
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            v-model.lazy="filters.cpf_cnpj"
            dense
            outlined
            color="primary"
            :label="$t('labels.cpf_cnpj')"
            type="text"
            v-mask="masks.cpfOrCnpj"
          />
        </v-col>

        <v-col cols="12" md="2">
          <v-select
            v-model.lazy="filters.status_abr"
            dense
            :items="InviteStatusTypes"
            item-text="name"
            item-value="id"
            outlined
            :color="'primary'"
            :label="$t('labels.status', { certificationType: 'ABR' })"
          />
        </v-col>

        <v-col cols="12" md="2">
          <v-select
            v-model.lazy="filters.status_bci"
            dense
            :items="InviteStatusTypes"
            item-text="name"
            item-value="id"
            outlined
            :color="'primary'"
            :label="$t('labels.status', { certificationType: 'BCI' })"
          />
        </v-col>
      </v-row>

      <v-row class="pa-0 ma-0">
        <v-col cols="12" md="2">
          <v-select
            v-model.lazy="filters.status_sbrhvi"
            dense
            :items="InviteStatusTypes"
            item-text="name"
            item-value="id"
            outlined
            :color="'primary'"
            :label="$t('labels.status', { certificationType: 'SBRVHI' })"
          />
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            outlined
            dense
            color="primary"
            :label="
              $t('labels.field_responsible', {
                field: 'Nome/CPF',
                programType: 'ABR/BCI'
              })
            "
            type="text"
            v-model.lazy="filters.responsaveis_abr"
          />
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            v-model.lazy="filters.responsaveis_sbrhvi"
            outlined
            dense
            color="primary"
            :label="
              $t('labels.field_responsible_leader', {
                field: 'Nome/CPF',
                programType: 'SBRHVI'
              })
            "
            type="text"
          />
        </v-col>

        <v-col cols="12" md="2">
          <v-text-field
            v-model.lazy="filters.unidade_produtiva_id"
            dense
            outlined
            color="primary"
            :label="$t('labels.id_producer_unit')"
            type="number"
          />
        </v-col>

        <v-col v-if="profile.canSeeSeachForUf" cols="12" md="2">
          <v-select
            v-model.lazy="filters.uf"
            item-text="name"
            item-value="abbreviation"
            color="primary"
            dense
            outlined
            :items="ufsTypes"
            :label="$t('labels.uf')"
          />
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col cols="12" md="5">
          <v-text-field
            v-model.lazy="filters.unidade_produtiva"
            dense
            outlined
            color="primary"
            :label="$t('labels.unity_productive')"
            type="text"
          />
        </v-col>
        <v-col cols="12" md="7">
          <div class="buttons-content">
            <v-btn
              color="primary"
              class="button"
              depressed
              width="150"
              @click="exportXlsx"
              >Exportar Excel</v-btn
            >
            <v-btn
              color="primary"
              class="button"
              depressed
              width="150"
              @click="search"
              >Pesquisar</v-btn
            >
            <v-btn
              color="white"
              class="button"
              depressed
              width="150"
              @click="clearFilters"
              >Limpar</v-btn
            >
          </div>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <div class="my-5 button-new-invite">
        <v-btn
          v-if="profile.canNewInvite && isOnSelectedDate"
          color="primary"
          @click="$router.push({ name: 'newInvite' })"
          depressed
          class="button"
          >{{ $t("buttons.new_invite") }}</v-btn
        >
      </div>

      <h2 class="table-title">
        {{ $t("text.titulo_produtores_grupo_produtores_convidados") }}
      </h2>

      <v-simple-table class="mt-5">
        <template slot="default">
          <thead>
            <tr>
              <th>
                {{ $t("labels.id_productor_or_group") }}
                <a
                  v-if="order.produtor_id === 'asc'"
                  @click="orderingAsc('produtor_id')"
                >
                  <v-icon v-text="'mdi-chevron-up'"></v-icon>
                </a>
                <a v-else>
                  <v-icon
                    v-text="'mdi-chevron-down'"
                    @click="orderingDesc('produtor_id')"
                  ></v-icon>
                </a>
              </th>
              <th>
                {{ $t("labels.productor_or_group") }}
                <a
                  v-if="order.produtor === 'asc'"
                  @click="orderingAsc('produtor')"
                >
                  <v-icon v-text="'mdi-chevron-up'"></v-icon>
                </a>
                <a v-else>
                  <v-icon
                    v-text="'mdi-chevron-down'"
                    @click="orderingDesc('produtor')"
                  ></v-icon>
                </a>
              </th>
              <th>
                {{ $t("labels.type") }}
                <a
                  v-if="order.tipo_produtor === 'asc'"
                  @click="orderingAsc('tipo_produtor')"
                >
                  <v-icon v-text="'mdi-chevron-up'"></v-icon>
                </a>
                <a v-else>
                  <v-icon
                    v-text="'mdi-chevron-down'"
                    @click="orderingDesc('tipo_produtor')"
                  ></v-icon>
                </a>
              </th>

              <th>
                {{ $t("labels.id_producer_unit") }}
                <a
                  v-if="order.unidade_produtiva_id === 'asc'"
                  @click="orderingAsc('unidade_produtiva_id')"
                >
                  <v-icon v-text="'mdi-chevron-up'"></v-icon>
                </a>
                <a v-else>
                  <v-icon
                    v-text="'mdi-chevron-down'"
                    @click="orderingDesc('unidade_produtiva_id')"
                  ></v-icon>
                </a>
              </th>

              <th>
                {{ $t("labels.unity_productive") }}
                <a
                  v-if="order.unidade_produtiva === 'asc'"
                  @click="orderingAsc('unidade_produtiva')"
                >
                  <v-icon v-text="'mdi-chevron-up'"></v-icon>
                </a>
                <a v-else>
                  <v-icon
                    v-text="'mdi-chevron-down'"
                    @click="orderingDesc('unidade_produtiva')"
                  ></v-icon>
                </a>
              </th>

              <th>
                {{ $t("labels.city") }}
                <a
                  v-if="order.municipio === 'asc'"
                  @click="orderingAsc('municipio')"
                >
                  <v-icon v-text="'mdi-chevron-up'"></v-icon>
                </a>
                <a v-else>
                  <v-icon
                    v-text="'mdi-chevron-down'"
                    @click="orderingDesc('municipio')"
                  ></v-icon>
                </a>
              </th>

              <th>
                {{ $t("labels.status", { certificationType: "ABR" }) }}
                <a
                  v-if="order.status_abr === 'asc'"
                  @click="orderingAsc('status_abr')"
                >
                  <v-icon v-text="'mdi-chevron-up'"></v-icon>
                </a>
                <a v-else>
                  <v-icon
                    v-text="'mdi-chevron-down'"
                    @click="orderingDesc('status_abr')"
                  ></v-icon>
                </a>
              </th>
              <th>
                {{ $t("labels.status", { certificationType: "BCI" }) }}
                <a
                  v-if="order.status_bci === 'asc'"
                  @click="orderingAsc('status_bci')"
                >
                  <v-icon v-text="'mdi-chevron-up'"></v-icon>
                </a>
                <a v-else>
                  <v-icon
                    v-text="'mdi-chevron-down'"
                    @click="orderingDesc('status_bci')"
                  ></v-icon>
                </a>
              </th>
              <th>
                {{
                  $t("labels.responsibles", { certificationType: "ABR/BCI" })
                }}
                <a
                  v-if="order.responsaveis === 'asc'"
                  @click="orderingAsc('responsaveis')"
                >
                  <v-icon v-text="'mdi-chevron-up'"></v-icon>
                </a>
                <a v-else>
                  <v-icon
                    v-text="'mdi-chevron-down'"
                    @click="orderingDesc('responsaveis')"
                  ></v-icon>
                </a>
              </th>

              <th>
                {{ $t("labels.status", { certificationType: "SBRHVI" }) }}
                <a
                  v-if="order.status_sbrhvi === 'asc'"
                  @click="orderingAsc('status_sbrhvi')"
                >
                  <v-icon v-text="'mdi-chevron-up'"></v-icon>
                </a>
                <a v-else>
                  <v-icon
                    v-text="'mdi-chevron-down'"
                    @click="orderingDesc('status_sbrhvi')"
                  ></v-icon>
                </a>
              </th>

              <th>
                {{ $t("labels.responsibles", { certificationType: "SBRHVI" }) }}
                <a
                  v-if="order.responsaveis_sbrhvi === 'asc'"
                  @click="orderingAsc('responsaveis_sbrhvi')"
                >
                  <v-icon v-text="'mdi-chevron-up'"></v-icon>
                </a>
                <a v-else>
                  <v-icon
                    v-text="'mdi-chevron-down'"
                    @click="orderingDesc('responsaveis_sbrhvi')"
                  ></v-icon>
                </a>
              </th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(producer, index) in producers">
              <tr
                :key="`tr-${index}`"
                v-if="!producer.unidades_produtivas.length"
              >
                <td>
                  {{ producer.produtor_id }}
                </td>

                <td>
                  <strong>{{ producer.produtor }}</strong>
                </td>

                <td>
                  {{ producer.tipo_produtor.descricao }}
                </td>

                <!-- Productive units info -->
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>

                <!-- Status SBRHVI -->
                <td>
                  <status
                    v-if="producer.status_sbrhvi"
                    :value="producer.status_sbrhvi.id"
                    >{{ producer.status_sbrhvi.descricao }}</status
                  >
                  <span v-else>Indisponível</span>
                </td>

                <!-- Responsibles for SBRHVI  -->
                <td>
                  {{ producer.responsaveis_sbrhvi.join(", ") }}
                </td>

                <!-- Actions -->
                <td>
                  <btn-link
                    v-if="
                      profile.canSeeAnswerInvite &&
                        !canSeeManageButton(producer)
                    "
                    @click="handleAnswerInvite(producer)"
                    >Responder Convite</btn-link
                  >
                  <btn-link
                    v-if="canSeeManageButton(producer)"
                    @click="handleManageProducer(producer)"
                    >Gerenciar</btn-link
                  >
                </td>
              </tr>

              <!-- Render rows based on productive units -->
              <tr
                v-for="(unityProductive,
                upIndex) in producer.unidades_produtivas"
                :key="`tr-${index}-${upIndex}`"
              >
                <!-- ID -->
                <td
                  v-if="upIndex === 0"
                  :rowspan="producer.unidades_produtivas.length"
                >
                  {{ producer.produtor_id }}
                </td>

                <!-- Producer name with rowspan -->
                <td
                  v-if="upIndex === 0"
                  :rowspan="producer.unidades_produtivas.length"
                >
                  <strong>{{ producer.produtor }}</strong>
                </td>

                <!-- Producer type -->
                <td
                  v-if="upIndex === 0"
                  :rowspan="producer.unidades_produtivas.length"
                >
                  {{ producer.tipo_produtor.descricao }}
                </td>

                <!-- Productive units info -->
                <td>{{ unityProductive.id }}</td>
                <td>{{ unityProductive.nome }}</td>
                <td>{{ unityProductive.municipio }}</td>
                <td>
                  <status
                    v-if="unityProductive.status_abr"
                    :value="unityProductive.status_abr.id"
                    >{{ unityProductive.status_abr.descricao }}</status
                  >
                  <span v-else>Indisponível</span>
                </td>
                <td>
                  <status
                    v-if="unityProductive.status_bci"
                    :value="unityProductive.status_bci.id"
                    >{{ unityProductive.status_bci.descricao }}</status
                  >
                  <span v-else>Indisponível</span>
                </td>
                <td>{{ unityProductive.responsaveis.join(", ") }}</td>

                <!-- Status SBRHVI -->
                <td
                  v-if="upIndex === 0"
                  :rowspan="producer.unidades_produtivas.length"
                >
                  <status
                    v-if="producer.status_sbrhvi"
                    :value="producer.status_sbrhvi.id"
                    >{{ producer.status_sbrhvi.descricao }}</status
                  >
                  <span v-else-if="producer.possui_insencao_sbrhvi">{{
                    $t("text.field_not_found")
                  }}</span>
                  <span v-else>Indisponível</span>
                </td>

                <!-- Responsibles for SBRHVI  -->
                <td
                  v-if="upIndex === 0"
                  :rowspan="producer.unidades_produtivas.length"
                >
                  {{ producer.responsaveis_sbrhvi.join(", ") }}
                  <span v-if="!producer.responsaveis_sbrhvi.length">{{
                    $t("text.field_not_found")
                  }}</span>
                </td>

                <!-- Actions -->
                <td
                  v-if="upIndex === 0"
                  :rowspan="producer.unidades_produtivas.length"
                >
                  <btn-link
                    v-if="
                      profile.canSeeAnswerInvite &&
                        !canSeeManageButton(producer)
                    "
                    @click="handleAnswerInvite(producer)"
                    >Responder Convite</btn-link
                  >
                  <btn-link
                    v-if="canSeeManageButton(producer)"
                    @click="handleManageProducer(producer)"
                    >Gerenciar</btn-link
                  >
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div>
      <v-row class="mt-8 footer-content" align="center">
        <v-col :cols="12" md="3" align-self="center">
          <span class="label-footer">
            {{
              `${$t("labels.show_register", {
                totalPerPage: tableInfo.totalPerPage,
                record: $tc("labels.record", tableInfo.totalPerPage),
                totalItems: tableInfo.totalItems
              })}`
            }}
          </span>
        </v-col>
        <v-col :cols="12" md="6">
          <v-pagination
            v-model="tableInfo.page"
            :length="tableInfo.totalPages"
          ></v-pagination>
        </v-col>
        <v-col :cols="12" md="3">
          <v-select
            v-model="tableInfo.limit"
            :items="perPageOptions"
            outlined
            color="primary-color-1"
          ></v-select>
        </v-col>
      </v-row>
      <manage-invites
        v-if="manageInviteState.value"
        :state="manageInviteState"
        @update="handleUpdateManagerProducer"
      />
      <answer-invite
        :state="answerInviteState"
        :key="`answer-invite-${answerInviteKey}`"
      />
      <expired-invited
        v-model="isDialogExpiredOpen"
        :state="answerInviteState"
      />
    </div>
  </v-card>
</template>

<script>
import ManageInvites from "@/modules/inviteds/views/components/ManageInvites";
import Status from "@/modules/inviteds/views/components/Status";
import BtnLink from "@/modules/core/views/components/BtnLink";
import { ProducerType } from "@/modules/core/enums/producer-type.enum";
import { uf } from "@/modules/core/enums/uf.enum";

import { InviteStatus } from "@/modules/core/enums/invite-status.enum";
import MasksMixin from "@/modules/core/mixins/masksMixin";
import { Profile } from "@/modules/inviteds/enums/profile.enum";
import AnswerInvite from "@/modules/invited/views/components/AnswerInvite";
import ExpiredInvited from "@/modules/invited/views/components/ExpiredInvited.vue";

import { mapActions, mapGetters } from "vuex";
import { debounce, pickBy, identity } from "lodash";
import { link } from "fs";

export default {
  name: "Inviteds",

  components: {
    ManageInvites,
    Status,
    BtnLink,
    AnswerInvite,
    ExpiredInvited
  },

  mixins: [MasksMixin],

  data: () => ({
    profiles: {
      [Profile.STATE.id]: {
        canSeeAnswerInvite: false,
        canSeeSeachForUf: false,
        canNewInvite: true
      },
      [Profile.ABRAPA.id]: {
        canSeeAnswerInvite: false,
        canSeeSeachForUf: true,
        canNewInvite: false
      },
      [Profile.INVITED.id]: {
        canSeeAnswerInvite: true,
        canSeeSeachForUf: false,
        canNewInvite: false
      }
    },

    manageInviteState: {
      value: false
    },
    answerInviteState: {
      activate: false,
      data: {
        acceptRules: false
      }
    },

    answerInviteKey: 0,

    isDialogExpiredOpen: false, // Começa fechado

    producers: [],

    tableInfo: {
      page: 1,
      totalItems: 0,
      totalPerPage: 0,
      totalPages: 0,
      limit: 10
    },

    filters: {},

    perPageOptions: [
      { value: 10, text: "10 itens/página" },
      { value: 25, text: "25 itens/página" },
      { value: 50, text: "50 itens/página" },
      { value: 100, text: "100 itens/página" }
    ],

    order: {
      produtor_id: "asc",
      produtor: "asc",
      tipo_produtor: "asc",
      unidade_produtiva_id: "asc",
      unidade_produtiva: "asc",
      municipio: "asc",
      responsaveis_sbrhvi: "asc",
      status_sbrhvi: "asc",
      status_abr: "asc",
      status_bci: "asc",
      responsaveis: "asc"
    },
    fieldOrder: ""
  }),

  computed: {
    ...mapGetters({
      userProfile: "inviteds/getUserProfile"
    }),

    profile() {
      return this.profiles[this.userProfile.id] ?? [];
    },

    headers() {
      return [
        {
          text: this.$t("labels.id_productor_or_group"),
          value: "produtor_id"
        },
        {
          text: this.$t("labels.productor_or_group"),
          value: "produtor"
        },
        {
          text: this.$t("labels.type"),
          value: "tipo"
        },
        {
          text: this.$t("labels.id_producer_unit"),
          value: "id_up"
        },
        {
          text: this.$t("labels.unity_productive"),
          value: "unidades_produtivas"
        },
        {
          text: this.$t("labels.city"),
          value: "unidades_produtivas"
        },
        {
          text: this.$t("labels.status", { certificationType: "ABR" }),
          value: "unidades_produtivas"
        },
        {
          text: this.$t("labels.status", { certificationType: "BCI" }),
          value: "unidades_produtivas"
        },
        {
          text: this.$t("labels.responsibles", {
            certificationType: "ABR/BCI"
          }),
          value: "unidades_produtivas"
        },
        {
          text: this.$t("labels.status", {
            certificationType: "SBRHVI"
          }),
          value: "responsaveis_sbrhvi"
        },
        {
          text: this.$t("labels.responsibles", {
            certificationType: "SBRHVI"
          }),
          value: "responsaveis_sbrhvi"
        },

        { value: "id" }
      ];
    },

    producerTypes() {
      return [{ id: "", name: "Todos" }, ...Object.values(ProducerType)];
    },

    ufsTypes() {
      return [{ abbreviation: "", name: "Todos" }, ...Object.values(uf)];
    },

    InviteStatusTypes() {
      return [{ id: "", name: "Todos" }, ...Object.values(InviteStatus)];
    },
    isOnSelectedDate() {
      const today = new Date();
      const year = today.getFullYear();

      // Normalizando as datas (removendo horário)
      const normalizeDate = date =>
        new Date(date.getFullYear(), date.getMonth(), date.getDate());

      const startDate = new Date(year, 0, 1); // 1 de janeiro - data para os testes
      // const endDate = new Date(year, 11, 31); // 31 de dezembro - data para os testes
      // const startDate = new Date(year, 1, 26); // 26 de fevereiro - data real
      const endDate = new Date(year, 0, 30); // 30 de junho - data real

      // Convertendo para a versão sem horário
      const startDateNormalized = normalizeDate(startDate);
      const todayNormalized = normalizeDate(today);
      const endDateNormalized = normalizeDate(endDate);

      // Verificando se hoje está entre a data de início e a data de fim
      const startDateCheck = todayNormalized >= startDateNormalized;
      const endDateCheck = todayNormalized >= endDateNormalized;

      return startDateCheck && endDateCheck;
    },
    canSeeModalExpiredInvitedComputed() {
      return (
        this.profile.canSeeAnswerInvite &&
        !this.canSeeManageButton(this.producer) &&
        this.isOnSelectedDate
      );
    }
  },

  created() {
    this.setFilterDebounced = debounce(async function(key, value) {
      this.setFilter(key, value);
    }, 800);
    // this.isDialogExpiredOpen = this.canSeeModalExpiredInvitedComputed; // Define o valor inicial
  },

  mounted() {
    this.getInviteds();
    this.isOnSelectedDate;
  },

  watch: {
    "tableInfo.limit": function() {
      this.tableInfo.page = 1;
      this.getInviteds();
    },

    "tableInfo.page": function() {
      this.getInviteds();
    },

    "answerInviteState.activate": function(newVal) {
      if (newVal) {
        this.answerInviteKey++;
      }
    }

    // canSeeModalExpiredInvitedComputed(newValue) {
    //   this.isDialogExpiredOpen = newValue; // Atualiza o estado do modal
    // }
  },

  methods: {
    ...mapActions("inviteds", ["fetchInvites"]),
    ...mapActions("invited", ["fetchInviteByInvitedIdAndType"]),
    ...mapActions("invited", ["fetchDownloadExcelFile"]),
    ...mapActions(["toggleSnackbar"]),

    async search() {
      this.tableInfo.page = 1;
      await this.getInviteds();
    },

    async orderingAsc(fieldOrder) {
      this.setOrdering(fieldOrder, "desc");
      this.fieldOrder = fieldOrder;
      this.getInviteds();
    },
    async orderingDesc(fieldOrder) {
      this.setOrdering(fieldOrder, "asc");
      this.fieldOrder = "-" + fieldOrder;
      this.getInviteds();
    },
    setOrdering(fieldOrder, directionFor) {
      if (fieldOrder === "produtor_id") {
        this.order.produtor_id = directionFor;
      } else if (fieldOrder === "produtor") {
        this.order.produtor = directionFor;
      } else if (fieldOrder === "produtor") {
        this.order.produtor = directionFor;
      } else if (fieldOrder === "tipo_produtor") {
        this.order.tipo_produtor = directionFor;
      } else if (fieldOrder === "unidade_produtiva_id") {
        this.order.unidade_produtiva_id = directionFor;
      } else if (fieldOrder === "unidade_produtiva") {
        this.order.unidade_produtiva = directionFor;
      } else if (fieldOrder === "municipio") {
        this.order.municipio = directionFor;
      } else if (fieldOrder === "responsaveis_sbrhvi") {
        this.order.responsaveis_sbrhvi = directionFor;
      } else if (fieldOrder === "status_sbrhvi") {
        this.order.status_sbrhvi = directionFor;
      } else if (fieldOrder === "status_abr") {
        this.order.status_abr = directionFor;
      } else if (fieldOrder === "status_bci") {
        this.order.status_bci = directionFor;
      } else if (fieldOrder === "responsaveis") {
        this.order.responsaveis = directionFor;
      } else {
        console.log(fieldOrder);
      }
    },

    async exportXlsx() {
      const data = await this.fetchDownloadExcelFile();
      const filename = "planilha-convidados.xlsx";
      const blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
      });
      var element = document.createElement("a");
      element.href = URL.createObjectURL(blob);
      element.download = filename;
      element.click();
      URL.revokeObjectURL(link.href);
    },

    async getInviteds() {
      try {
        const data = await this.fetchInvites(this.getPayloadData());

        this.producers = this.formatData(data.results);

        this.setTableInfo({
          totalItems: data.count,
          totalPages: data.total_pages,
          totalPerPage: data.per_page
        });
      } catch (error) {
        this.toggleSnackbar({
          text: error.response?.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    getPayloadData() {
      const cpf_cnpj = this.filters?.cpf_cnpj?.replace(/\D/g, "") ?? null;
      const filters = { ...this.filters, cpf_cnpj };

      return {
        ...pickBy(this.filters, identity),
        page: this.tableInfo.page,
        limit: this.tableInfo.limit,
        ordering: this.fieldOrder
      };
    },

    formatData(producers) {
      return producers.map(producer => {
        return { ...producer };
      });
    },

    handleUpdateManagerProducer(producer) {
      this.handleManageProducer(producer);
      this.getInviteds();
    },

    async handleManageProducer(producer) {
      if (!producer) {
        return;
      }

      const data = await this.fetchInviteDetail(producer);

      this.manageInviteState = {
        ...this.manageInviteState,
        producer,
        value: true,
        managingInvite: { convidado_id: producer.produtor_id, ...data }
      };
    },

    async handleAnswerInvite(producer) {
      const data = await this.fetchInviteDetail(producer);
      // const data = mockDetailInvite.mockDetailAbrbciWithSbrhviLeaderUnaccepted;

      this.answerInviteState = {
        ...this.answerInviteState,
        activate: true,
        producer,
        data: {
          convidado_id: producer.produtor_id,
          ...data
        }
      };
    },

    async fetchInviteDetail(producer) {
      return await this.fetchInviteByInvitedIdAndType({
        invitedId: producer.produtor_id,
        type: producer.tipo_produtor.id
      });
    },

    setTableInfo(meta) {
      this.tableInfo = {
        ...this.tableInfo,
        ...meta
      };
    },

    setFilter(key, value) {
      this.filters[key] = value;
    },

    clearFilters() {
      this.filters = {};
      this.tableInfo = {};
    },

    cannotAnswerInviteSbrhvi(producerOrGroup) {
      const isNotInvitedToSbrhvi = !producerOrGroup?.responsavel_convite_sbrhvi;
      const IsSbrhviNotionNotSent =
        producerOrGroup?.status_sbrhvi?.id !== InviteStatus?.INVITE_SENT?.id;
      return IsSbrhviNotionNotSent || isNotInvitedToSbrhvi;
    },

    cannotAnswerInviteAbrBci(producerOrGroup) {
      const isNotInvitedToAbrBci = !producerOrGroup?.responsavel_convite_abr;

      if (isNotInvitedToAbrBci) {
        return true;
      }

      return producerOrGroup?.unidades_produtivas?.every(productiveUnit => {
        return productiveUnit?.status_abr.id !== InviteStatus?.INVITE_SENT?.id;
      });
    },

    canSeeManageButton(producerOrGroup) {
      if (!this.profile.canSeeAnswerInvite) {
        return true;
      }

      return (
        this.cannotAnswerInviteSbrhvi(producerOrGroup) &&
        this.cannotAnswerInviteAbrBci(producerOrGroup)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.card-content {
  min-width: 400px;
  min-height: 1000px;

  .button-new-invite {
    .button {
      margin-right: 20px;
    }
  }

  .buttons-content {
    height: 60%;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}

@media screen and (max-width: 959px) {
  .button-new-invite {
    display: flex;
    justify-content: center;

    .button {
      margin-right: 0px !important;
    }
  }
  .buttons-content {
    flex-direction: column;
    justify-content: center !important;
    align-items: center;

    .button {
      width: 100% !important;
    }
  }

  .table-title {
    text-align: center;
  }

  .footer-content {
    text-align: center;
  }
}
</style>
