<template>
  <v-dialog v-model="isOpen" width="620" scrollable persistent>
    <v-card>
      <v-card-title class="primary--text title-content">
        O prazo para adesão ao programa expirou
      </v-card-title>
      <v-card-text class="text-center">
        Conforme regulamento ABR/BCI, as adesões do programa ABR/BCI são até dia
        31 de junho de {{ currentYear }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex  buttons-content">
        <v-btn color="primary" class="button" @click="doLogout">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: {
    value: Boolean // Conexão com v-model do pai
  },
  computed: {
    isOpen() {
      return this.value;
    },
    currentYear() {
      return new Date().getFullYear();
    }
  },
  methods: {
    ...mapActions("inviteds", ["logout"]),
    ...mapActions(["setNoHasLoggedUser"]),

    async doLogout() {
      this.setNoHasLoggedUser();
      this.logout();
      window.location.replace(process.env.VUE_APP_SSO_URL);
    }
  }
};
</script>
<style lang="scss" scoped>
.title-content,
.text-center {
  justify-content: center;
}

.buttons-content {
  justify-content: end;
  align-items: center;
}
@media screen and (max-width: 960px) {
  .title-content {
    justify-content: center;
  }

  .buttons-content {
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    margin: 12px 0;

    .button {
      width: 100%;
      margin: 0 !important;
    }
  }
}
</style>
